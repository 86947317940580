// http.js
import axios from 'axios';
import { ElMessageBox, ElMessage } from 'element-plus';

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // api的base_url
    timeout: 5000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 可以在这里添加请求头等信息
        return config;
    },
    error => {
        // 请求错误处理
        console.log(error); // for debug
        Promise.reject(error).then(() => {
            // 可以在这里进行错误处理
            return Promise.reject(error);
        });
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data;
        // 根据返回的状态码做相应处理，例如这里以200为正确响应
        if (res.code !== 200) {
            ElMessageBox.alert(res.message, '错误', { type: 'error' }).then(() => {
                if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                    // 根据错误代码进行对应操作，例如登录失效等
                }
            });
            return Promise.reject('error');
        } else {
            return response.data;
        }
    },
    error => {
        console.log('err' + error); // for debug
        ElMessage({
            message: '服务器异常',
            type: 'error',
            duration: 5 * 1000
        });
        return Promise.reject(error);
    }
);

export default service;