<template>
  <h1>冒泡排序</h1>
  <div class="left-aligned">
    <el-text>
      reverse的逻辑为先把字符串转为字符数组，再通过双指针遍历，交换字符位置，得到翻转后的字符串。
      <br>
      时间复杂度
      <br>
      reverse的时间复杂度为O(n)，空间复杂度为O(1)
      <br>
      <br>
    </el-text>
    <CopyCollapse :title="title" :contentToCopy="contentToCopy"/>
  </div>

  <div class="test-area">
    <el-input v-model="StringText" type="textarea" :autosize="{ minRows: 2 }" placeholder="请输入一个有效的 字符串 "/>
    <el-tooltip content="随机生成一个自付出，长度在 1 到 100 之间" placement="top">
      <el-button type="primary" @click="randomString" class="test-interval">随机字符串</el-button>
    </el-tooltip>
    <el-button type="primary" @click="reverse" class="test-interval">反转</el-button>
    <el-button type="primary" @click="reset" class="test-interval">重置</el-button>
    <el-input v-model="reverseResult" type="textarea" placeholder="反转结果" :autosize="{ minRows: 2 }" readonly class="test-interval"/>
    <el-text>{{ resultText }}</el-text>
  </div>
</template>

<style scoped>
.left-aligned {
  text-align: left; /* 设置文本靠左对齐 */
}

.test-area {
  margin-top: 20px;
}

.test-interval {
  margin-top: 10px;
}
</style>

<script setup>
import {ref} from 'vue';
import {stringReverse} from './string.js';
import {errorMessage, successMessage} from "@/utils/message.js";
import CopyCollapse from '@/components/CopyCollapse.vue';
const StringText = ref('');
const reverseResult = ref('');
const resultText = ref('');
const title = ref('reverse检查是否含义代理对');
const contentToCopy = ref('什么是代理对（Surrogate Pairs）<br>' +
    'Unicode 标准中，有些字符的码点（code point）超过了 16 位的范围（即超过 U+FFFF）。为了在 16 位的环境中表示这些字符，Unicode 引入了代理对的概念。一个代理对由两个 16 位的代码单元组成，分别称为高代理（high surrogate）和低代理（low surrogate）。<br>' +
    '例如，字符 😊（U+1F60A）在 UTF-16 编码中表示为两个 16 位的代码单元：\\uD83D\\uDE0A 。<br>'+
    '当我们把这个字符串转为字符数组时，会得到两个元素：\\uD83D 和 \\uDE0A 。<br>'+
    '在 Java 中，\\uD83D 和 \\uDE0A 是两个单独的字符，而不是一个完整的字符 😊 。<br>' );

const reverse = async () => {
  try {
    const response = await stringReverse(StringText.value);
    successMessage('排序成功');
    reverseResult.value = response.data;
    resultText.value = `程序运行时间为：${response.dateTime}秒`;
  } catch (error) {
    errorMessage(error);
  }
};
const randomString = () => {
  StringText.value = generateRandomString(1, 100);
}
function generateRandomString(minLength, maxLength) {
  const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }

  return randomString;
}
const reset = () => {
  StringText.value = '';
  reverseResult.value = '';
  resultText.value = '';
};
</script>