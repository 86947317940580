<script setup>

</script>

<template>
  <div class="logo-container">
    <a href="/" class="logo">
      <img src="/images/favicon.ico" alt="logo" />
    </a>
  </div>
</template>

<style scoped>
.logo-container {
  display: flex;
  align-items: center;
  height: 55px;
}
.logo {
  display: flex;
}
</style>