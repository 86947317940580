<template>
<!--  <h4 class="side-bar-title">菜单</h4>-->
  <el-menu ref="menu" :router="true" :default-active="activeIndex" class="el-menu-vertical-demo">
    <menu-item v-for="item in menuTree" :key="item.id" :item="item" />
  </el-menu>
</template>

<style scoped>
.el-menu-vertical-demo {
  height: 95%;
}
.side-bar-title {
  text-align: left;
  font-weight: bold;
  padding: 10px 0 10px 0px;
}
</style>

<script setup>
import { ref, onMounted, watch } from 'vue';
import {useRoute} from 'vue-router';
import { ElMenu } from 'element-plus';
import MenuItem from './MenuItem.vue';
import {getMenuTree} from "@/basic/js/basic.js";

const route = useRoute();
const activeIndex = ref(route.path);
const menuTree = ref([]);

const fetchMenuTree = async () => {
  try {
    const response = await getMenuTree();
    menuTree.value = response.data;
  } catch (error) {
    console.error('Failed to fetch menu tree:', error);
  }
};

onMounted(() => {
  fetchMenuTree();
});

watch(() => route.path, (newPath) => {
  activeIndex.value = newPath;
});
</script>
