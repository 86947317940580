// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../basic/Home.vue';
import Error404 from '../basic/Error404.vue';
import BubbleSort from '../views/algorithm/sort/BubbleSort.vue';
import StringReverse from '../views/algorithm/string/StringReverse.vue';

const routes = [
    {
        path: '/:pathMatch(.*)*', // 通配符路由
        name: 'Error404',
        component: Error404,
        meta: { title: '404 - 页面未找到' },
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: '首页' },
    },
    {
        path: '/bubbleSort',
        name: 'BubbleSort',
        component: BubbleSort,
        meta: { title: '冒泡排序' },
    },
    {
        path: '/stringReverse',
        name: 'StringReverse',
        component: StringReverse,
        meta: { title: '字符串反转' },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || '工具';
    next();
});
export default router;
