<!-- src/views/Home.Vue -->
<template>
  <div>
    <h1>首页</h1>
  </div>
  <div>不知道写什么，先占着位置</div>
  {{ message}}
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      message: null
    }
  },
/*  methods: {
    fetchData() {
      this.$http.get('/test/test').then(response => {
        console.log(response);
        this.message = response.msg;
      }).catch(error => {
        console.error(error);
      });
    }
  },
  mounted() {
    // 当组件挂载到DOM上后调用方法
    this.fetchData();
  }*/
};
</script>

<style scoped>
</style>
