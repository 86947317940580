import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import MarkdownItVue from 'vue3-markdown-it';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import { randomArr } from './basic/js/tools';
import http from './utils/http'; // 引入封装的http请求

const app = createApp(App);
// 设置网页标题
document.title = '工具';
//使用路由器
app.use(router);
//使用ElementPlus
app.use(ElementPlus);
//使用MarkdownItVue
app.use( MarkdownItVue);
//将http请求挂载到全局
app.config.globalProperties.$http = http;
app.config.globalProperties.$randomArr = randomArr;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app');
