<script setup>

</script>

<template>
  <!--  <div class="error-404">
      <h1>404 Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>-->
  <div class="container">
    <img src="/images/404.webp" alt="404 Not Found">
  </div>
  <p>The page you are looking for does not exist.</p>
</template>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container img {
  max-width: 100%;
  height: auto;
}
</style>