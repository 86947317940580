<template>
  <div>
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1">
        <template #title>
          <div class="title-container">
            <span>{{ title }}</span>
            <el-tooltip content="复制" placement="top">
              <el-icon class="copy-icon" @click.stop="copyText">
                <CopyDocument/>
              </el-icon>
            </el-tooltip>
          </div>
        </template>
        <div class="content-container">
          <div ref="contentToCopy" class="content-to-copy">
            <vue3-markdown-it :source="displayContent"/>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {CopyDocument} from '@element-plus/icons-vue';

export default {
  components: {
    CopyDocument
  },
  props: {
    title: {
      type: String,
      required: true
    },
    contentToCopy: {
      type: String,
      required: true
    },
    contentType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeNames: []
    };
  },
  computed: {
    displayContent() {
      // 在最前面添加一行代码
      return `\`\`\`${this.contentType}\n${this.contentToCopy}`;
    }
  },
  methods: {
    async copyText() {
      try {
        await navigator.clipboard.writeText(this.contentToCopy);
        this.$message({
          message: '复制成功',
          type: 'success'
        });
      } catch (err) {
        this.$message({
          message: '复制失败，请手动复制',
          type: 'error'
        });
      }
    }
  }
};
</script>

<style scoped>
.content-container {
  position: relative;
}

.content-to-copy {
  background-color: #f0f0f0; /* 设置背景颜色为灰色 */
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.copy-icon {
  cursor: pointer;
  font-size: 15px;
  color: #409eff;
  margin-right: 8px;
}

.copy-icon:hover {
  color: #66b1ff;
}
</style>


