<template>
  <h1>冒泡排序</h1>
  <div class="left-aligned">
    <el-text>
      从列表的第一个元素开始，比较相邻的两个元素,如果第一个元素比第二个元素大，则交换它们的位置。
      移动到下一个元素，重复上述步骤，直到整个列表没有任何需要交换的元素。
      <br>
      实际上第一次就是把最大的元素放到最后面，第二次就是把第二大的元素放到倒数第二个位置，以此类推。
      <br><br>
      冒泡排序的时间复杂度
      <br>
      最佳情况：O(n)（当数组已经有序时，只需进行一次遍历）
      <br>
      最坏情况：O(n^2)（当数组是逆序时，需要进行 n*(n-1)/2 次比较）
      <br>
      平均情况：O(n^2)
    </el-text>
    <el-collapse style="margin-top: 20px">
      <CopyCollapse title="代码实现" :contentToCopy="markdownContent" contentType="java"/>
    </el-collapse>
  </div>

  <div class="test-area">
    <el-input v-model="arr" type="textarea" :autosize="{ minRows: 2 }"
              placeholder="请输入一个有效的 JSON 格式的数组，例如：[1,3,2,5]"/>
    <el-tooltip content="随机生成一个数组，范围在 1 到 1000 之间，数组长度为 10 到 100 之间" placement="top">
      <el-button type="primary" @click="randomArr" class="test-interval">随机数组</el-button>
    </el-tooltip>
    <el-button type="primary" @click="arrSort" class="test-interval">排序</el-button>
    <el-button type="primary" @click="reset" class="test-interval">重置</el-button>
    <el-input v-model="arrSortResult" type="textarea" placeholder="排序结果" :autosize="{ minRows: 2 }" readonly
              class="test-interval"/>
    <el-text>{{ resultText }}</el-text>
  </div>
</template>

<style scoped>
.left-aligned {
  text-align: left; /* 设置文本靠左对齐 */
}

.test-area {
  margin-top: 20px;
}

.test-interval {
  margin-top: 10px;
}
</style>

<script setup>
import {getCurrentInstance, ref} from 'vue';
import {bubbleSort} from './sort.js';
import {errorMessage, successMessage} from "@/utils/message.js";
import CopyCollapse from '@/components/CopyCollapse.vue';

const {proxy} = getCurrentInstance();
const arr = ref('');
const arrSortResult = ref('');
const resultText = ref('');
const arrSort = async () => {
  // 验证输入格式
  if (!arr.value.trim()) {
    errorMessage('请输入数组');
    return;
  }
  let parsedArr;
  try {
    parsedArr = JSON.parse(arr.value);
    if (!Array.isArray(parsedArr)) {
      errorMessage('输入不是一个有效的数组');
      return;
    }
  } catch (e) {
    errorMessage('输入不是一个有效的 JSON 格式的数组');
    return;
  }

  try {
    const response = await bubbleSort(parsedArr);
    successMessage('排序成功');
    arrSortResult.value = JSON.stringify(response.data);
    resultText.value = `程序运行时间为：${response.dateTime}秒`;
  } catch (error) {
    errorMessage(error);
  }
};
const randomArr = () => {
  const minLength = 10;
  const maxLength = 100;
  const minNum = 1;
  const maxNum = 1000;
  const decimalPlaces = 2; // 可选参数，指定小数位数

  arr.value = JSON.stringify(proxy.$randomArr(minLength, maxLength, minNum, maxNum, decimalPlaces));
}
const reset = () => {
  arr.value = '';
  arrSortResult.value = '';
  resultText.value = '';
};

const markdownContent = ref(
    'import java.math.BigDecimal;\n' +
    'import java.util.Arrays;\n' +
    '\n' +
    'public class BubbleSort {\n' +
    '    public static void main(String[] args) {\n' +
    '        BigDecimal[] arr = {new BigDecimal("5"), new BigDecimal("3"), new BigDecimal("1"), new BigDecimal("4"), new BigDecimal("2")};\n' +
    '        int n = arr.length;\n' +
    '        boolean swapped;\n' +
    '        //总执行次数为数组长度，因为从0开始，所以总次数为n-1\n' +
    '        for (int i = 0; i < n - 1; i++) {\n' +
    '            swapped = false;\n' +
    '            //n-1-i是每次比较后，对应的最后一个元素为最大的，所以每次需要减少一次i的值\n' +
    '            for (int j = 0; j < n - 1 - i; j++) {\n' +
    '                if (arr[j].compareTo(arr[j + 1]) > 0) {\n' +
    '                    // 交换 arr[j] 和 arr[j + 1]\n' +
    '                    BigDecimal temp = arr[j];\n' +
    '                    arr[j] = arr[j + 1];\n' +
    '                    arr[j + 1] = temp;\n' +
    '                    swapped = true;\n' +
    '                }\n' +
    '            }\n' +
    '            // 如果没有发生交换，说明数组已经有序，提前退出\n' +
    '            if (!swapped) {\n' +
    '                break;\n' +
    '            }\n' +
    '        }\n' +
    '        System.out.println(Arrays.toString(arr));\n' +
    '    }\n' +
    '}\n');
</script>