/**
 * 生成随机数组（含小数）
 *
 * @param {number} minLength 最小长度
 * @param {number} maxLength 最大长度
 * @param {number} minNum 最小值
 * @param {number} maxNum 最大值
 * @param {number} [decimalPlaces=0] 小数位数，默认为0（整数）
 * @returns {number[]} 随机数组
 */
export const randomArr = (minLength, maxLength, minNum, maxNum, decimalPlaces = 0) => {
    if (minLength > maxLength || minNum > maxNum) {
        throw new Error('最小值不能大于最大值');
    }

    let length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
    let randomArr = [];

    for (let i = 0; i < length; i++) {
        let randomNum = (Math.random() * (maxNum - minNum) + minNum).toFixed(decimalPlaces);
        randomArr.push(parseFloat(randomNum));
    }

    return randomArr;
};